.captcha-img-wrapper {
  @extend .input-group-text;

  line-height: 1rem !important;
  padding: 1px !important;
  border-radius: .25rem 0 0 .25rem;
  background-color: white; 

  img {
    max-height: 1.75rem;
    border-radius: .25rem;
  }
}

#id_wagtailcaptcha_1 { 
  // @extend .form-control;

  width: 60px !important;
}

.invalid-feedback {
  display: block;
}
