hr {
    margin-top: 0;
    margin-bottom: 0;
}

h2, h3, h4, h5, h6 {
    margin-top: 1.5em;
    margin-bottom: 0.75em;
}

h2 {
    @extend .h3;

    .aside &, 
    aside & {
        @extend .h5;
        padding-bottom: 0.25em;
        border-bottom: 1px solid $hr-border-color;
        margin-bottom: 1.5em;
    }
}

p {
    hyphens: auto;
    hyphenate-limit-chars: auto 3;
}

.text-muted {
    color: gray !important;
}

aside, 
.aside {
    @extend .small;
}

.wagtail-table table {
    @extend .table;
}

.form .badge {
    font-size: 1rem;
    font-weight: 300;  // 400;
}

.navbar-site {
    font-family: $headings-font-family;
    background-color: white !important; 
    border-bottom: 1px solid $hr-border-color;

    body.has_hero_image & {
        border-bottom-color: white;
    }

    .navbar-brand img {
        max-width: 210px;
    }

    .nav-link {
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    .nav-link-online-akademie {
        background-color: rgba(245, 245, 245);
        // color: white !important;
        border-radius: 10px;
        padding: 0.25em 0.5em;
        margin: 0.25em 0;
    }

    .dropdown-toggle::after {
        display: none;
    }
    .dropdown-menu {
        border-width: 0 0 1px 0;
        border-color: white; 
        border-style: solid;
        background-color: rgba(255,255,255,0.65);
    }
    .dropdown-item {
        padding-left: 0.5em;
    }
}

blockquote {
    margin-top: 4em;
    margin-bottom: 2em;
    
    p {
        font-family: $font-family-sans-serif;
        font-size: larger;
        font-weight: 300;
        font-style: italic;
    }

    .blockquote-footer {
        font-family: $font-family-sans-serif;
        font-weight: 300;
        font-style: normal;
        background-color: transparent;
    }
}


.img-grayscale {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}



.accordion-toggle {

    &:before {
        content: "\f106";

        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        font-size: 0.75em;
        display: inline-block;
        padding-right: 1rem;
        vertical-align: middle;
    }
    &.collapsed:before {
        content: "\f107";
    }
}

.collapse-toggle {
    &:before {
        content: "\f106";

        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        font-size: 0.75em;
        display: inline-block;
        padding-left: 0.25rem;
        vertical-align: middle;
    }
    &.collapsed:before {
        content: "\f107";
    }
}

// .navbar-nav .show > .nav-link, 
// .navbar-nav .active > .nav-link, 
// .navbar-nav .nav-link.show, 
// .navbar-nav .nav-link.active,
// .navbar-nav .nav-link:hover, 
// .navbar-nav .nav-link:focus {
//     &.nav-link-section-events { color: var(--section-color-events); }
//     &.nav-link-section-profiles { color: var(--section-color-profiles); }
//     &.nav-link-section-trainings { color: var(--section-color-trainings); }
// }

.navbar-nav .nav-link {
    // font-weight: 700;
    font-size: x-large;
}


.block-truncated {

    .toggle-truncate {
        border-radius: 3px;
        padding: 1px 3px;
        display: inline-block;
        white-space: nowrap;
        font-size: smaller;
        cursor: pointer;
        color: $link-color;

        &:hover {
            background-color: var(--primary-light);
        }
    }

    .truncated      { display: block; }
    .non-truncated  { display: none; }

    &.block-truncated-revealed {
        .truncated      { display: none; }
        .non-truncated  { display: block; }
    }
}


.video-card {
    overflow: hidden;
}

.responsive-object {
    position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.modal-header {
    align-items: flex-start;
}
