.hero {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;

    position: relative;
    padding-top: calc(100% / (1800px / 380px));
    // max-height: 300px;
    max-width: 1200px + 200px;

    margin-left: auto;
    margin-right: auto;

    &.hero-wide {
      max-width: 100%;
    }

    .attribution {
      @extend .text-muted;
      position: absolute;
      right: 0;
      // bottom: 0;
      padding: 3px 10px;
      margin: 0;
      font-size: xx-small;
      text-align: center;
      display: inline-block;
      background-color: rgba(255,255,255,0.7);
    }
    
}
