html {
    overflow-y: scroll;
    scroll-behavior: smooth;
    scroll-padding-top: 130px
}


main,
aside {
  display: block;
}

.main-wrapper {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover; 

  // stack gradient over bg img to get a fade effect
  // https://stackoverflow.com/a/29833502/5071435
  background-image: linear-gradient(rgba(255,255,255, .95), rgba(255,255,255,.95)), url(../img/bg.optim.webp);
}
main {
  padding: 2rem 0 1rem 0;
  background-color: rgba(255,255,255, .65); 

  .page-akademie & {
    background-color: transparent;
  }
}

aside {

  font-size: 1em;
  
  // padding-top: $h1-font-size + $headings-margin-bottom + $headings-margin-bottom + 2rem;
  &:first-of-type h2,
  &:first-of-type h3,
  &:first-of-type h4 {
    margin-top: 0;
  }
}

footer {
  background-color: rgba(245, 245, 245, 0.5);
  padding-bottom: 1em;
  // line-height: $line-height-sm;

  p {
    padding-bottom: 0.5em;
  }

  .container {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .footer-nav {
    // line-height: $line-height-lg;
  }

  .footer-meta {

    a[href^="tel:"]::before,
    a[href^="https://www.google.com/maps"]::before {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: var(--fa-display, inline-block);
      font-style: normal;
      font-variant: normal;
      line-height: 1;
      text-rendering: auto;
      font-family: var(--fa-style-family-classic);
      margin-right: 5px;
      text-align: center;
      width: 1.25em;
    }

    a[href^="tel:"]::before {
      content: "\f3cf";
    }

    a[href^="https://www.google.com/maps"]::before {
      content: "\f5a0";
    }

    .row > div {
      border-top: 1px solid rgba(0,0,0,.1);
    }
  }

}

.navbar-site {
  // background-color: black  !important;
  transition: all 1s ease;
}

.sticky-top {
  top: -1px;
}

.nav-item-home {
  visibility: hidden;
  opacity: 0;
  width: 0px;
  transition: visibility 0s linear 500ms, opacity 500ms, width 300ms;
}
.is-sticky .nav-item-home {
  visibility: visible;
  opacity: 1;
  width: 55px;
  transition: visibility 0s linear 0s, opacity 500ms, width 300ms;
}
.nav-item-home a img {
  max-height: 1em;
  height: 1em;
}


@include media-breakpoint-up(md) {
  .nav-item-meta {
    position: absolute;
    top: -5.75rem;

    &#nav-item-login {
      right: 13%;
    }
    &#nav-item-right {
      right: 18%;
    }

    a.nav-link {
        .navbar-site & {
          color: white;
          background-color: $yaf-red;
          border-radius: 10rem;
          display: inline-block;
          padding: .25em .6em;
          font-size: 75%;
          font-weight: 700;
          line-height: 1;
          text-align: center;
          white-space: nowrap;
          vertical-align: baseline;
          border-radius: .25rem;
          transition: color .5s ease-in-out, background-color .5s ease-in-out;

          &:hover { 
            color: $yaf-red;
            background-color: white;
          }
      }
    }

  }
}


// .sticky.is-sticky {
//   background-color: lime !important;
//   position: fixed;
//   left: 0;
//   right: 0;
//   top: 0;
//   z-index: 1000;
//   width: 100%;
//   transition: all 1s ease;
// }

h1, .h1 {
    font-size: 1.75rem;
}

.heading-row {

  margin-bottom: 2em;

  h1 {
    margin: 0;
    padding: 0;

  }

  // .badge-section {
  //   body.section-events & { color: white; background-color: var(--section-color-events); }
  //   body.section-profiles & { color: white; background-color: var(--section-color-profiles); }
  //   body.section-trainings & { color: white; background-color: var(--section-color-trainings); }
  // }

}

.body-row {
  h2:first-of-type {
    margin-top: 0;
  }
}

.subheading {
    margin: 0.5em 0;
}

.listing p {
  margin-bottom: 0.5rem;
}


@media (max-width: 576px) {
  .navbar-site .dropdown,  
  .navbar-site .dropdown-menu {
    text-align: right;
  }
}


.pdf-thumbnail {
  &:hover img {
    border-color: $yaf-red !important;
  }
}


.media {
  @extend .d-flex;
  // @extend .align-items-center;

  > img {
    @extend .flex-shrink-0;
  }

  .media-body {
    @extend .flex-grow-1;
    @extend .ms-3;
  }
}