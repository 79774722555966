$box-shadow-std: 0px 1px 2px rgba(46,41,51,0.08),0px 2px 4px rgba(71,63,79,0.08);
$box-shadow-light: 0px 1px 2px rgba(46,41,51,0.04),0px 2px 4px rgba(71,63,79,0.04);
$box-shadow-hover:  0px 2px 4px rgba(46,41,51,0.08),0px 4px 8px rgba(71,63,79,0.16);
$box-shadow-invisible:  0px 1px 2px rgba(46,41,51,0.0),0px 2px 4px rgba(71,63,79,0.0);



.accordion-item {
  margin: 1rem 0;
  padding: 0 1.5rem;
  border: none;
  box-shadow: $box-shadow-light;

  &:hover {
    background-color: white;
  }
  .accordion-body {
    padding-bottom: 1.5rem;
  }
}


.teasers-block {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    display: grid;
    margin: 2rem 0;
  
    @include media-breakpoint-up(sm) {
      grid-template-columns: 1fr 1fr;
    }

    &.only-one-teaser {
      grid-template-columns: 1fr;
    }
   
    .teaser {
      text-decoration: none;
      display: inline-flex;
      padding: 1.5rem;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 0;
      transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
      // transition: background-color 250ms;
      box-shadow: $box-shadow-std;

      &:hover {
          //  background-color: white;
          filter: brightness(105%);
          box-shadow: $box-shadow-hover;
      }
  
      .link-arrow {
        color: inherit; 
      }
  
      h2,
      h3 {
        margin-top: 0; 
        border-bottom: 1px solid inherit;
      }
  
      p {
        margin-bottom: 0;
      }
  }

}


.calltoaction-block {
  background-color: rgba(255, 215, 0, 0.1);
  box-shadow: $box-shadow-hover;
  border-radius: 5px;
  margin: 1rem 0;
  padding: 2rem 1rem;

  h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

}


.timetable-container {
  overflow-x: scroll;
  margin: 2rem 0;
}
.timetable-column {
  flex: 1;
  padding: 0.25rem;
  background-color: rgba(255, 215, 0, 0.1);
  border-radius: 5px;
}

.course {
    box-shadow: $box-shadow-invisible;
    background-color: rgba(255,255,255,0.2);
    cursor: pointer;

    &:hover {
      box-shadow: $box-shadow-hover;
      // background-color: $yaf-red-light;
      background-color: rgba(255, 215, 0, 0.5);
    }
    p {margin: 0.15rem 0;}
}