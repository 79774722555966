.section-nav {
	position: sticky;
	top: 70px;
	align-self: start;
}

.section-nav a:hover,
.section-nav a:focus,
.section-nav a.active {
    color: rgb(33, 37, 41);
}

.section-nav a {
  margin-left: 4rem;
  text-align: left;
  text-decoration: none;
  display: block;
  padding: .1rem 0;
  color: silver;
  font-size: smaller;
  transition: all 50ms ease-in-out; /* 💡 This small transition makes setting of the active state smooth */
}

.trainings-listing-item {
  scroll-margin: 70px;
}

section > header {
  position: sticky; 
  top: 60px;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 9;
}
section > header > h2 {
  display: inline-block;
  padding: 0 10px;
  border-bottom: 3px solid var(--section-color-ausbildungen);
}


.training-type-title {
    // font-weight: 400;
    color: var(--section-color-ausbildungen) !important;
    white-space: nowrap;
}

.prev-next a {
    @extend .h2;

    font-size: 1rem;
    vertical-align: middle;
}

@include media-breakpoint-down(md) {

    .template-trainings-index .heading-row {
        margin-bottom: 0;
    }

    .training-type-title {
        font-size: 1.25rem;
    }
    .prev-next {
        flex-grow: 1;
        text-align: right;

        a {
            display: inline-block;
            font-size: smaller;
            vertical-align: baseline;
            text-align: right;
        }
    }
}
